<template>
  <div class="global-content">
    <div class="banner">
      <img src="@/assets/itgs/about-banner.png">
    </div>
    <div class="inner global-shadow">
      <div><i class="fa fa-file-text global-title-fa-big"></i><span class="global-title">介绍</span></div>
      <van-divider style="margin-bottom:0"/>
      <van-cell title="产品介绍" is-link to='/itgs/intro' />
      <van-cell title="使用说明" is-link @click="hint" />
      <van-cell title="版本号" value="v1.0.0" />
    </div>
    <tabBar/>
  </div>
</template>
<script>
import tabBar from '@/components/itgs/tabBar.vue'

export default {
  name:'About',
  components: {
    tabBar
  },
  methods:{
    hint(){
      this.$toast('敬请期待！');
    }
  }
}
</script>
<style lang="scss" scoped>
.banner{
  margin-bottom: 10px;
  img{
    width: 100%;
    height: 265px;
    border-radius: 10px;
  }
}
.inner{
  padding: 2%;background-color: white;border-radius:10px;
  .van-cell{padding: 16px}
  .van-cell__title{font-weight: bold;color: #656565;}
}
</style>